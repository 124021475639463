import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

import OwlCarousel from 'react-owl-carousel2'
import '../../node_modules/react-owl-carousel2/lib/styles.css'

class Testimonials extends React.Component {
  render () {    

    const options = {
      items: 1,
      margin: 25,
      loop: true,
      mouseDrag: false,
      rewind: true,
      autoplay: false,
      autoplayTimeout: 10000,
      responsive:{
          1200:{
              items:3
          },
          992:{
              items:2
          },
          767:{
              items:1
          }

      }        
    };

    return (
        <>        
        <h3>Our Google Reviews</h3>
        {this.props.reviews && (
            <OwlCarousel ref="testimonials" options={options}>
                {this.props.reviews.map((quote) => {

                var authorImage = {
                    display:            'flex',
                    backgroundImage:    `url(${quote.profile_photo_url})`,
                    backgroundSize:     'cover',
                    backgroundPosition: 'center',
                    borderRadius:       '100%',
                    width:              '80px',
                    height:             '80px',
                    marginBottom:       '1.5rem'                
                };

                return <div className="testimonial" key={quote.author_name}>
                    <span style={ authorImage } />
                    <p>{ quote.text }</p>
                    <h6>{ quote.author_name }</h6>                                
                </div>              
                })}
            </OwlCarousel>
        )}
    </>
    )
  }
}

export default () => (
  <StaticQuery
    query={graphql`
    query {
        googlePlacesPlace {
          name
          rating
          childrenGooglePlacesReview {
            author_name
            text
            profile_photo_url
          }
        }
      }
    `}
    render={(data) => (
      <Testimonials reviews={data.googlePlacesPlace.childrenGooglePlacesReview} />
    )}
  />
)